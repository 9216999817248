import React from 'react'
import { Link } from 'gatsby'
import MainInner from '../components/MainInner'
import Seo from '../components/Seo'

export default function ThankYou() {
  return (
    <>
      <Seo title="Thank you" />

      <MainInner>
        <h2>Success!</h2>
        <p>
          Thank you for your purchase. We're adding your new course to your
          account right now. Head to the <Link to="/learn">Learn</Link> page to
          see it.
        </p>
      </MainInner>
    </>
  )
}
